// 
// Pricing Page
// 
.price-label {
	position: absolute;
	top: -10px;
	margin: 0 auto;
	left: 0;
	right: 0;
	width: 100px;
	padding: 5px 10px;
}

.price-sign {
	font-size: 15px;
	position: absolute;
	top: 5px;
	margin-left: -10px;
}