// #############################
// Theme Colors
// #############################

$themecolor: #009efb;
$theme-light: #fff;
$theme-dark: #212529;
//$background-alt: #26c6da;

$main-body-bg: #fff !default;

// ======================================================
// Dark Theme Colors Settings
// ======================================================
$theme-dark-bg-layout: #323743;
$theme-dark-text-color: #b2b9bf !default;
$theme-dark-border-color: rgba(255, 255, 255, 0.2) !default;
$theme-dark-focus-border-color: rgba(255, 255, 255, 0.4) !default;
$theme-dark-card-bg: #272b34;
$theme-dark-bg-light: #3c424e;
$main-body-bg-dark: $gray-900 !default;
$background-alt: #2b2b2b !default;

// #############################
// Skins For changing sidebar and logo bg colors
// #############################
$skin1: #90a4ae;
$skin2: #55ce63;
$skin3: #363d4a;
$skin4: #009efb;
$skin5: #7460ee;
$skin6: #fff;

$gray-0: $white;

// #############################
// Shadow default
// #############################

$shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);

// #############################
// Transitions
// #############################

$transitions: 0.2s ease-in;
$transparent-dark-bg: rgba(0, 0, 0, 0.05);

// #############################
// Preloader settings here
// #############################
$spinner-width: 64px;
$spinner-height: 64px;
$spinner-position: calc(50% - 3.5px);

// #############################
// Header settings here
// #############################

/// height-width setting
$topbar: $theme-light;
$topbar-height: 70px;
$topbar-navlink-height: 70px;
$mega-dd-height: 480px;
$topbar-dropdown-width: 300px;
$horizontal-navbar-brand-width: 230px;

/// pading setting
$topbar-navlink-padding: 0px 0.75rem;
$topbar-togglar-padding: 0 15px;
$navbar-collapse-padding: 0px 10px 0 0;
$topbar-navbrand-padding: 0 10px;
$minisidebar-topbar-navbrand-padding: 0px 20px 0px 20px;
$horizontal-pagewrapper-padding-top-sm: 78px;
$horizontal-pagewrapper-padding-top-lg: 125px;
$horizontal-boxed-page-breadcrumb-padding-lg: 25px 0 0;
$horizontal-boxed-page-breadcrumb-container-padding-lg: 20px 0 0;

/// font-size setting
$topbar-navlink-font-size: 17px;
$topbar-navlink-text-size: 14px;

// #############################
// Sidebar Settings here
// #############################
/// Text color setting
$sidebar: $theme-light;
$sidebar-alt: #e8eff0;
$sidebar-text: #fff;
$sidebar-icons: #fff;
$sidebar-text-dark: #54667a;
$sidebar-icons-dark: #a6b7bf;

/// font-size setting
$sidebar-icon-size: 21px;
$sidebar-first-level-icon-size: 14px;
$sidebar-footer-text-size: 18px;
$sidebar-link-font-size: 15px;
$nav-small-cap-font-size: 12px;

/// Line height setting
$sidebar-link-line-height: 25px;
$sidebar-icon-line-height: 25px;

/// sidebar height-width setting
$sidebar-width-full: 240px;
$sidebar-width-iconbar: 180px;
$sidebar-width-mini: 60px;
$sidebar-icon-width: 30px;
$sidebarheight: 54px;
$sidebar-li-width: 220px;
$sidebar-first-level-width: 400px;

/// sidebar links padding setting
$sidebar-link-padding: 14px 35px 14px 8px;
$rtl-sidebar-link-padding: 14px 11px 14px 35px;
$minisidebar-link-padding: 14px 35px 14px 12px;
$rtl-minisidebar-link-padding: 14px 12px 14px 35px;
$sidebar-first-level-padding: 0 0 10px 0;
$minisidebar-first-level-padding: 0 0 10px;
$minisidebar-link-first-level-padding: 10px 35px 10px 12px;
$rtl-minisidebar-link-first-level-padding: 10px 12px 10px 35px;
$sidebar-first-level-link-padding: 12px 15px 12px 37px;
$rtl-sidebar-first-level-link-padding: 12px 37px 12px 15px;
$sidebar-footer-inner-padding: 15px;
$sidebar-notibadge-inner-padding-x: 10px;

/// User Profile img setting
$profile-pic-width-full: 50px;
$profile-pic-width-mini: 35px;

/// box shadow setting
$horizontal-list-shadow: 5px 10px 20px rgba(0, 0, 0, 0.1);

// #############################
// Page Layout Settings
// #############################

$boxed-width: 1200px; // boxed layout width
$page-wrapper-container-padding: 20px 30px;
$container-min-height: calc(100vh - 180px);
$footer-padding: 15px 20px;
$lft: left;
$rgt: right;

// #############################
// Sticky Left & Right Sidebar
// #############################
$left-part-width: 260px;
$right-part-width: calc(100% - 260px);
$right-part-height: calc(100vh - 64px);

$font-weight-medium: 500;

// #############################
// Card Setting
// #############################
$card-margin-bottom: 30px;
$card-title-bottom: 10px;
$card-subtitle-bottom: 10px;
