// 
//  Preloader
// 
.preloader {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	background:  $white;
	z-index: 99999;
}

.lds-ripple {
	position: absolute;
	width: $spinner-width;
	height: $spinner-width;
	top: $spinner-position;
	left:  $spinner-position;
	display: inline-block;
	.lds-pos {
		position: absolute;
		border-color: 2px solid $info;
		border-radius: 50%;
		opacity: 1;
		animation: lds-ripple 1s cubic-bezier(0, 0.1, 0.5, 1) infinite;
	}
	.lds-pos:nth-child(2) {
		animation-delay:-0.5s;
	}
}

@keyframes lds-ripple {
  	0% {
  		top: 28px;
  		left: 28px;
  		width: 0;
  		height: 0;
    	opacity: 0;
  	}
  	5% {
  		top: 28px;
  		left: 28px;
  		width: 0;
  		height: 0;
    	opacity: 1;
  	}  
  	100% {
  		top: -1px;
  		left: -1px;
  		width: 58px;
  		height: 28px;
    	opacity: 0;
  	}
}
